import React, {useEffect, useState} from 'react'
import './Planning.scss'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {EditingState, IntegratedEditing, ViewState} from '@devexpress/dx-react-scheduler';
import {
    AppointmentForm,
    Appointments,
    AppointmentTooltip,
    ConfirmationDialog,
    DateNavigator,
    DayView,
    MonthView,
    Scheduler,
    TodayButton,
    Toolbar,
    ViewSwitcher,
    WeekView
} from '@devexpress/dx-react-scheduler-material-ui';
import {withStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import Room from '@material-ui/icons/Room';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import http from "../http-common";
import {Route, Switch, useHistory} from "react-router-dom";
import MarkAttendance from "../AttendanceManagement/MarkAttendance";
import AuthService from "../services/auth.service"
import authHeader from "../services/auth-header";

const style = theme => ({
    todayCell: {
        backgroundColor: fade(theme.palette.primary.main, 0.1),
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.14),
        },
        '&:focus': {
            backgroundColor: fade(theme.palette.primary.main, 0.16),
        },
    },
    weekendCell: {
        backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
        '&:hover': {
            backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
        },
        '&:focus': {
            backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
        },
    },
    today: {
        backgroundColor: fade(theme.palette.primary.main, 0.16),
    },
    weekend: {
        backgroundColor: fade(theme.palette.action.disabledBackground, 0.06),
    },
    textCenter: {
        textAlign: 'center',
    },
    icon: {
        color: theme.palette.action.active,
    },
    appointment: {
        borderRadius: '10px',
        backgroundColor: '#1c4eaa',
        borderColor: '#ffffff',
        borderWidth: '2px',
        '&:hover': {
            backgroundColor: '#ffac40',
            borderColor: '#1c4eaa',
        },
    }
});

function Planning() {
    return (
        <Switch>
            <Route exact path={'/planeerija'}>
                <Planner/>
            </Route>
            <Route path={'/planeerija/:eventId'}>
                <MarkAttendance/>
            </Route>
        </Switch>
    )
}

function Planner() {
    const [currentView, setCurrentView] = useState('Day');
    const [sessions, setSessions] = useState([]);
    const [sessionLocations, setSessionLocations] = useState([])
    const [sessionTypes, setSessionTypes] = useState([])
    const [sessionGroups, setSessionGroups] = useState([])
    const [sessionInstructors, setSessionInstructors] = useState([])

    const getData = async (name, setData) => {
        const response = await http.get(`/api/${name}`, {headers: authHeader()})
        setData(response.data)
        console.log(response.data);
    }

    useEffect(() => {
        getData('sessionLocations', setSessionLocations).catch((err) => console.log(err.data))
        getData('sessionTypes', setSessionTypes).catch((err) => console.log(err.data))
        getData('sessionGroups', setSessionGroups).catch((err) => console.log(err.data))
        getData('sessionInstructors', setSessionInstructors).catch((err) => console.log(err.data))
        getData('sessions', setSessions).catch((err) => console.log(err.data))
        if (AuthService.getCurrentUser().admin === true) {
            setCurrentView('Week')
        }
    }, []);

    function commitChanges({added, changed, deleted}) {

        const postData = async (action, target, id) => {
            // method for adding, updating, deleting session, instructorSession, groupSession
            // name - what is updated (api url)
            // action - what is to be done (add, update, delete)
            // target - the object given to add or update (or id when deleting)
            // id - the object id, that is going to be updated
            if (action === 'post') {
                console.log("addable", target)
                const response = await http.post(`/api/sessions/`, target, {headers: authHeader()})
                console.log(response.data);
            } else if (action === 'update') {
                const response = await http.put(`/api/sessions/${id}`, target, {headers: authHeader()})
                console.log(response.data);
            } else if (action === 'delete') {
                const response = await http.delete(`/api/sessions/${target}`, {headers: authHeader()})
                console.log(response.data);
            }
            // requests all sessions after making new sessions
            // easier to ask everything again (because of the separate session, session's instructors and groups handling)
            getData('sessions', setSessions).catch((err) => console.log(err.data))
        }

        setSessions(() => {
            let data = sessions;
            if (added) {
                if (!added.type && sessionTypes.length > 0) {
                     added['type'] = sessionTypes[0]
                }
                if (!added.location && sessionLocations.length > 0) {
                    added['location'] = sessionLocations[0]
                }
                postData('post', added).catch((err) => console.log(err))
            }
            if (changed) {
                for (let id of Object.keys(changed)) {
                    postData('update', changed[id], id).catch((err) => console.log(err))
                }
            }
            if (deleted !== undefined) {
                postData('delete', deleted).catch((err) => console.log(err))
            }
            return data;
        });
    }

    return (
        <div className="planning-main">
            <Paper>
                <Scheduler
                    data={sessions}
                    locale={"et-EE"}
                    height={660}
                    firstDayOfWeek={1}
                >
                    <ViewState
                        currentViewName={currentView}
                        onCurrentViewNameChange={setCurrentView}
                    />
                    <DayView
                        startDayHour={10}
                        endDayHour={22}
                        displayName="päev"
                    />
                    <WeekView
                        name="work-week"
                        displayName="töönädal"
                        excludedDays={[0, 6]}
                        startDayHour={10}
                        endDayHour={22}
                        timeTableCellComponent={TimeTableCell}
                    />
                    <WeekView
                        displayName="nädal"
                        startDayHour={10}
                        endDayHour={22}
                        timeTableCellComponent={TimeTableCell}
                    />
                    <MonthView
                        displayName="kuu"
                    />
                    <EditingState
                        onCommitChanges={commitChanges}
                    />
                    <IntegratedEditing/>
                    <Appointments
                        appointmentComponent={Appointment}
                    />
                    <ConfirmationDialog/>
                    <AppointmentTooltip
                        showCloseButton
                        showOpenButton
                        showDeleteButton
                        contentComponent={Content}
                    />
                    <AppointmentForm // editing view TODO override date format to 24h instead 12h
                        basicLayoutComponent={BasicLayout(sessionTypes, sessionLocations, sessionInstructors, sessionGroups)} // add custom fields here
                        recurrenceLayoutComponent={RecurrenceLayout}
                        textEditorComponent={TextEditor} // disable notes
                        booleanEditorComponent={BooleanEditor} // disable "Terve päev" and "Korda"
                        dateEditorComponent={DateEditor}
                        messages={{
                            moreInformationLabel: '', // disable more messages label
                            detailsLabel: "Sündmuse andmed",
                            titleLabel: "pealkiri",
                            daily: "päev",
                            weekly: "nädal",
                            monthly: "kuu",
                            yearly: "aasta",
                            daysLabel: "päeva tagant",
                            weeksOnLabel: "nädala tagant, päevadel:",
                            monthsLabel: "kuu tagant",
                            yearsLabel: "aasta tagant",
                            repeatEveryLabel: "Korda iga",
                            never: "mitte kunagi",
                            firstLabel: "esimene",
                            secondLabel: "teine",
                            thirdLabel: "kolmas",
                            fourthLabel: "neljas",
                            lastLabel: "viimane",
                            endRepeatLabel: "Lõpeta sündmuse kordamine",
                            onLabel: "",
                            everyLabel: "iga",
                            theLabel: "iga",
                            ofEveryMonthLabel: "kuupäeval",
                            afterLabel: "pärast",
                            occurrencesLabel: "toimumisel",
                            allDayLabel: "Terve päev",
                            repeatLabel: "Korda",
                            commitCommand: "Salvesta",
                        }}
                    >
                    </AppointmentForm>
                    <Toolbar/>
                    <DateNavigator/>
                    <TodayButton
                        messages={{today: "täna"}}
                    />
                    <ViewSwitcher/> {/*dropdown list*/}
                    <ConfirmationDialog
                        messages={{
                            discardButton: "tühista",
                            deleteButton: "kustuta",
                            cancelButton: "tagasi",
                            confirmDeleteMessage: "Kas oled kindel, et tahad selle sündmuse kustutada?",
                            confirmCancelMessage: "Tühista salvestamata muudatused?"
                        }}
                    />
                </Scheduler>
            </Paper>
        </div>
    );
}

function replaceObjectKeys(object, replacements) {
    // translating keys -- replacing data object keys with the ones that react-select eats
    return Object.keys(object).map((key) => {
        const newKey = replacements[key] || key;
        return {[newKey]: object[key]}
    }).reduce((a, b) => Object.assign({}, a, b));
}

function BasicLayout(sessionTypes, sessionLocations, sessionInstructors, sessionGroups) {
    return ({onFieldChange, appointmentData, ...restProps}) => {
        const onTypeChange = (nextValue) => onFieldChange({type: findItemById(sessionTypes, nextValue)})
        const onLocationChange = (nextValue) => onFieldChange({location: findItemById(sessionLocations, nextValue)})
        const onCoachesChange = (nextValue) => onFieldChange({
            instructors: nextValue === null ? [] : nextValue.map(sessionInstructors => replaceObjectKeys(sessionInstructors, {
                'value': 'id',
                'label': 'name'
            }))
        })
        const onGroupsChange = (nextValue) => onFieldChange({
            groups: nextValue === null ? [] : nextValue.map(sessionGroups => replaceObjectKeys(sessionGroups, {
                'value': 'id',
                'label': 'name'
            }))
        })
        const animatedComponents = makeAnimated();

        const findItemById = (values, id) => {
            const item = values.find(item => item.id === id)
            return item ? item : null
        }

        return (
            <AppointmentForm.BasicLayout
                appointmentData={appointmentData}
                onFieldChange={onFieldChange}
                dateEditorComponent={DateEditor}
                {...restProps}
            >
                <AppointmentForm.Label text="Tüüp"/>
                <AppointmentForm.Select // values are stored as id-s
                    type={"String"}
                    value={appointmentData.type ? appointmentData.type.id : sessionTypes.length > 0 ? sessionTypes[0].id : 0}
                    onValueChange={onTypeChange}
                    availableOptions={sessionTypes.map(type => ({...type, text: type.name}))}
                />

                <AppointmentForm.Label text="Asukoht"/>
                <AppointmentForm.Select
                    type={"String"}
                    value={appointmentData.location ? appointmentData.location.id : sessionLocations.length > 0 ? sessionLocations[0].id : 0}
                    onValueChange={onLocationChange}
                    availableOptions={sessionLocations.map(location => ({
                        ...location,
                        text: location.name + ", " + location.address
                    }))}
                />
                <AppointmentForm.Label text="Treenerid"/>
                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={onCoachesChange}
                    options={sessionInstructors}
                    defaultValue={appointmentData.instructors ? appointmentData.instructors.map(instructor => replaceObjectKeys(instructor, {
                        'id': 'value',
                        'name': 'label'
                    })) : ''}
                />
                <AppointmentForm.Label text="Grupid"/>
                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={onGroupsChange}
                    options={sessionGroups}
                    defaultValue={appointmentData.groups ? appointmentData.groups.map(group => replaceObjectKeys(group, {
                        'id': 'value',
                        'name': 'label'
                    })) : ''}
                />
            </AppointmentForm.BasicLayout>
        );
    }
}

const TimeTableCellBase = ({classes, ...restProps}) => {
    const {startDate} = restProps;
    const date = new Date(startDate);

    const getClass = () => {
        if (date.getDate() === new Date().getDate()) return classes.todayCell
        if (date.getDay() === 0 || date.getDay() === 6) return classes.weekendCell
        return ""
    }
    return <WeekView.TimeTableCell {...restProps} className={getClass()}/>;

};

const TimeTableCell = withStyles(style, {name: 'TimeTableCell'})(TimeTableCellBase);

const Content = withStyles(style, {name: 'Content'})(({children, appointmentData, classes, ...restProps}) => (
    // the popup that shows when clicked on the event
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData} className={classes.content}>
        <Grid container alignItems="center">
            <Grid item xs={2} className={classes.textCenter}>
                <Room className={classes.icon}/>
            </Grid>
            <Grid item xs={10}>
                <span>{appointmentData.location === undefined || appointmentData.location === null ? "" : appointmentData.location.name + ", " + appointmentData.location.address}</span>
            </Grid>
            <Grid item xs={2} className={classes.textCenter}>
                <PeopleIcon className={classes.icon}/>
            </Grid>
            <Grid item xs={10}>
                <span> {appointmentData.instructors === undefined ? "" : appointmentData.instructors.map(({name}) => name).join(", ")}</span>
            </Grid>
            <Grid item xs={2} className={classes.textCenter}>
                <GroupWorkIcon className={classes.icon}/>
            </Grid>
            <Grid item xs={10}>
                <span>{appointmentData.groups === undefined ? "" : appointmentData.groups.map(({name}) => name).join(", ")}</span>
            </Grid>
        </Grid>
    </AppointmentTooltip.Content>
));


const Appointment = withStyles(style, {name: 'Appointment'})(({children, classes, ...restProps}) => {
    let history = useHistory();

    function handleClick() {
        console.log(children[1].props.data.id)
        history.push(`/planeerija/${children[1].props.data.id}`);
    }

// the box that shows the event in the calendar
    return (<Appointments.Appointment {...restProps} className={classes.appointment} onDoubleClick={handleClick}>
        {children}
    </Appointments.Appointment>)
});

const RecurrenceLayout = (props) => {
    return <AppointmentForm.RecurrenceLayout locale={'et-EE'} {...props} readOnly/>;
};

const TextEditor = (props) => {
    if (props.type === 'multilineTextEditor') {
        return null;
    }
    return <AppointmentForm.TextEditor {...props} />;
};

const DateEditor = (props) => {
    return <AppointmentForm.DateEditor locale={'et-EE'}  {...props} />;
};

const BooleanEditor = () => {
    return null;
};

export default Planning;