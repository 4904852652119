import React, {useState} from "react";
import './PersonAttendance.scss';
import {ArrowRightTwoTone, ArrowDropDownTwoTone} from "@material-ui/icons";

function PersonAttendance({person, update, usingEquipment, bikes, fuelTypes, instructors}) {
    const [expanded, setExpanded] = useState(false)

    function toggle(attending) {
        setExpanded(attending)
        update('attending', attending)
    }

    function getName() {
        return `${person.first_name} ${person.last_name}`
    }

    return (
        <div className='person'>
            <button
                key={person.id} className='row'
                disabled={person.attending === false}
                onClick={() => setExpanded(!expanded)}>
                {getName()}
                {usingEquipment && <span className={person.attending ? '' : 'hide-arrow'}>
                    <ArrowRightTwoTone className={expanded ? 'hide-arrow' : 'arrow right'}/>
                    <ArrowDropDownTwoTone className={expanded ? 'arrow down' : 'hide-arrow'}/>
                </span>}
                <Switch enabled={person.attending === true} toggle={toggle}/>
            </button>
            {usingEquipment && person.attending === true && expanded &&
            <PersonInfo
                person={person}
                update={update}
                setExpanded={setExpanded}
                bikes={bikes}
                fuelTypes={fuelTypes}
                instructors={instructors}
            />}
        </div>
    );
}

function PersonInfo({person, update, bikes, fuelTypes, instructors}) {
    function updateValue(type) {
        return (value) => {
            update(type, value)
        }
    }
    function getFuelNames() {
        return fuelTypes.map(({name}) => name)
    }

    function getBikeNames() {
        return bikes.map(({name}) => name)
    }

    function getInstructorNames() {
        return instructors.map(({label}) => label)
    }

    return (
        <div className='person_info'>
            <SelectValue
                name={'Klubi ratas'}
                value={person.bike_name}
                update={updateValue("bike_name")}
                valueList={getBikeNames()}
            />
            <SelectValue
                name={'Klubi kütus'}
                value={person.fuel_name}
                update={updateValue("fuel_name")}
                valueList={getFuelNames()}>
                <input
                    type="number"
                    value={person.fuel_quantity}
                    min="1" name="quantity"
                    onChange={e => updateValue("fuel_quantity")(parseInt(e.target.value))}/>
            </SelectValue>
            <SelectValue
                name={'Hooldus'}
                value={person.maintenance}
                update={updateValue("maintenance")}
                valueList={getInstructorNames()}
            />
        </div>
    );
}

function SelectValue({name, valueList, value, update, children}) {
    function isNotDefault(value) {
        return value !== valueList[0]
    }

    function toggle() {
        update(isNotDefault(value) ? valueList[0] : valueList[1])
    }

    return (
        <form className={isNotDefault(value) ? '' : 'disabled'}>
            <label className="label" htmlFor={name}>{name}</label>
            <Switch className="switch" enabled={isNotDefault(value)} toggle={toggle}/>
            <span>
                <select className="select" value={value} name={name} onChange={e => update(e.target.value)}>
                {valueList.filter(item => isNotDefault(item)).map((item) =>
                    <option key={item} value={item}>{item}</option>)}}
                </select>
                {children}
            </span>
        </form>
    )
}

function Switch({enabled, toggle}) {
    return (
        <label className="switch">
            <input type="checkbox" checked={enabled} onChange={() => toggle(!enabled)}/>
            <span className="slider round"/>
        </label>
    );
}

export default PersonAttendance