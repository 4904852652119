import React, {useState} from "react";
import './Login.scss';

import AuthService from "../services/auth.service";

function Login({children, setUser}) {
    return (
        (AuthService.getCurrentUser() === null || (AuthService.getCurrentUser() != null && AuthService.getCurrentUser().access < (new Date()).getTime()))
            ? <LoginPage setUser={setUser}/>
            : <>{children}</>
    )
}

function LoginPage({setUser}) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    function validateCredentials() {
        AuthService.login(username, password).then(
            (response) => {
                setUser({username: response.username, admin: response.admin})
            },
            // TODO: Error message. For example wrong password
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(resMessage)
            }
        );
        setUser({username: '', admin: false})
    }

    function emailPasswordReset() {
        alert('email sent')
    }

    return (
        <div id='login_page'>
            <img className="logo" src={require("../resources/emsa_logo.png")} alt={"Eesti motospordi akadeemia"}/>
            <input className="login-input" value={username} onChange={e => setUsername(e.target.value)}
                   placeholder='Kasutaja'/>
            <input className="login-input" value={password} onChange={e => setPassword(e.target.value)} type='password'
                   placeholder='Parool'/>
            <p onClick={() => emailPasswordReset()}>Unustasid parooli?</p>
            <button className="login-button regularButton" onClick={() => validateCredentials()}>Logi sisse</button>
        </div>)
}


export default Login;
