import React, {useEffect, useState} from 'react';
import './GroupEdit.scss';
import {FaTimes, FaSearch, FaArrowRight} from 'react-icons/fa';
import ConfirmDialog from "../Helper/ConfirmDialog";
import http from "../http-common";
import authHeader from '../services/auth-header';

function GroupEdit({attendingGroup, groupId, doneEditing, deleteGroup}) {
    const [groupParticipants, setParticipants] = useState(attendingGroup)
    const [addableParticipants, setAddableParticipants] = useState([])
    const [confirmOpen, setConfirmOpen] = useState(false)

    const addParticipant = async (participantId) => {
        await http.get(`/api/groups/${groupId}/${participantId}`, {headers: authHeader()})
    }

    const removeParticipant = async (participantId) => {
        await http.delete(`/api/groups/${groupId}/${participantId}`, {headers: authHeader()})
    }

    useEffect(() => {
        const getAddableParticipants = async () => {
            await http.get(`/api/groups/assignable/${groupId}`, {headers: authHeader()}).then(result => setAddableParticipants(result.data.result))
        }

        getAddableParticipants()
    }, [groupId]);


    function deletePerson(name) {
        const person = groupParticipants.filter(person => person.first_name === name)[0]
        setParticipants(groupParticipants.filter(person => person.first_name !== name))
        removeParticipant(person.id)
        setAddableParticipants([...addableParticipants, person])
    }

    function cancel() {
        doneEditing()
    }

    function addToGroup(name) {
        const person = addableParticipants.filter(person => person.first_name === name)[0]
        setParticipants([...groupParticipants, person])
        addParticipant(person.id)
        setAddableParticipants(addableParticipants.filter(person => person.first_name !== name))
    }

    return (
        <main>
            <div className="group-blocks">
                <div className="groupview-column">
                    <h4 className="group-block-name">Sellesse gruppi lisamata liikmed:</h4>
                    <AddablePeopleList people={addableParticipants} addToGroup={addToGroup}/>
                </div>
                <div className="groupview-column">
                    <h4 className="group-block-name">Grupi liikmed:</h4>
                    <PeopleInGroupList people={groupParticipants} deletePerson={deletePerson}/>
                </div>
                <span/>
                <div className="groupButtons">
                    <button className="regularButton save" onClick={() => cancel(groupParticipants)}>Tagasi</button>
                    <button className="regularButton remove" onClick={() => setConfirmOpen(true)}>Kustuta grupp</button>
                    <ConfirmDialog
                        title="Kustuta grupp?"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={() => deleteGroup()}
                    >
                        Kas oled kindel, et soovid selle grupi kustutada??
                    </ConfirmDialog>
                </div>
            </div>
        </main>
    )
}

function AddablePeopleList({people, addToGroup}) {
    const [nameFilterInputValue, setNameFilterInputValue] = useState('')
    const [bikeFilterInputValue, setBikeFilterInputValue] = useState('')

    return (
        <div className="flex2">
            <div className="groupSearchPerson sticky">
                <div>
                    <FaSearch className="fasearch" size={17}/>
                    <label>Nimi</label>
                </div>
                <div>
                    <FaSearch className="fasearch" size={17}/>
                    <label>Tsikli suurus</label>
                </div>
                <input value={nameFilterInputValue} onChange={e => setNameFilterInputValue(e.target.value)}/>
                <input value={bikeFilterInputValue} onChange={e => setBikeFilterInputValue(e.target.value)}/>
            </div>
            {people.filter(person => person.first_name.toLowerCase().startsWith(nameFilterInputValue.toLowerCase())
                || person.last_name.toLowerCase().startsWith(nameFilterInputValue.toLowerCase()))
                .filter(person => person.bike_cc.toString().startsWith(bikeFilterInputValue.toLowerCase()))
                .map(person =>
                    <div key={person.id} className="personDiv">
                        <label>{person.first_name} {person.last_name}</label>
                        <label>{person.bike_cc}cc</label>
                        <button className="personAdd" onClick={() => addToGroup(person.first_name)}>
                            <FaArrowRight/>
                        </button>
                    </div>
                )}
        </div>
    )
}

function PeopleInGroupList({children, people, deletePerson}) {
    const [nameFilterInputValue, setNameFilterInputValue] = useState('')
    const [bikeFilterInputValue, setBikeFilterInputValue] = useState('')

    return (
        <div className="flex2">
            <div className="groupSearchPerson">
                <div>
                    <FaSearch className="fasearch" size={17}/>
                    <label>Nimi</label>
                </div>
                <div>
                    <FaSearch className="fasearch" size={17}/>
                    <label>Tsikli suurus</label>
                </div>
                <input value={nameFilterInputValue} onChange={e => setNameFilterInputValue(e.target.value)}/>
                <input value={bikeFilterInputValue} onChange={e => setBikeFilterInputValue(e.target.value)}/>
            </div>
            {children}
            {people.filter(person => person.first_name.toLowerCase().startsWith(nameFilterInputValue.toLowerCase())
                || person.last_name.toLowerCase().startsWith(nameFilterInputValue.toLowerCase()))
                .filter(person => person.bike_cc.toString().startsWith(bikeFilterInputValue.toLowerCase()))
                .map(person =>
                    <div key={person.id} className="personDiv">
                        <label>{person.first_name} {person.last_name}</label>
                        <label>{person.bike_cc}cc</label>
                        <button className="personRemove" onClick={() => deletePerson(person.first_name)}>
                            <FaTimes/>
                        </button>
                    </div>
                )}
        </div>
    )
}


export default GroupEdit;
