export function compareValues(key, order = 'asc') {
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        if (varA > varB) return (order === 'desc' ? -1 : 1);
        if (varA < varB) return (order === 'desc' ? 1 : -1);
        return 0;
    }
}