import React from "react";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

function ErrorAlert({message, open, setOpen}) {
    return (
        <div className="error-alert">
            <Collapse in={open}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    Midagi läks valesti — <strong> {message} </strong>
                </Alert>
            </Collapse>
        </div>
    );
}

export default ErrorAlert;