import React, {useEffect, useState} from "react";
import './Accounts.scss';
import EditAccount from "./EditAccount";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import http from "../http-common";
import authHeader from "../services/auth-header";

function Accounts() {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentlyEditing, setCurrentlyEditing] = useState({});
    const [activity, setActivity] = useState("");

    useEffect(() => {
        const getData = async () => {
            const response = await http.get(`/api/accounts`, {headers: authHeader()})
            setUsers(response.data)
        }

        getData().catch((err) => console.log(err))
    }, []);

    function saveUser(newUser) {
        if (activity === "add") {
            setUsers([...users, newUser])
        }
        if (activity === "edit") {
            setUsers(users.map(user => {
                if (user.name === currentlyEditing.name) return newUser
                return user;
            }))
        }
        stopEditing()
    }

    function stopEditing() {
        setShowModal(false)
        setCurrentlyEditing({})
    }

    function AddUser() {
        setActivity("add")
        setShowModal(true)
    }

    function editUser(name) {
        setActivity("edit")
        setCurrentlyEditing({...users.filter(user => user.name === name)[0]})
        setShowModal(true)
    }

    return (
        <>
            {showModal && <EditAccount save={saveUser} userInfo={currentlyEditing} cancel={stopEditing}/>}
            <div className="accounts-main">
                <div className="addUser">
                    <button className="regularButton" onClick={() => AddUser()}>Lisa uus kasutaja
                    </button>
                </div>
                <div className="userTable">
                    <table>
                        <thead className="tableHeader">
                        <tr>
                            <th>Kasutajanimi</th>
                            <th>Nimi</th>
                            <th>Meiliaadress</th>
                            <th>Õigused</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map(({username, name, email, admin, active}) =>
                            <tr key={name} className={!active ? "inactive" : ""}>
                                <td>{username}</td>
                                <td>{name}</td>
                                <td>{email}</td>
                                <td>{!active ? 'inaktiivne' : admin ? 'administraator' : 'treener'}</td>
                                <td>
                                    <IconButton
                                        className="edit-account"
                                        title="Muuda kasutajat"
                                        onClick={() => editUser(name)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Accounts;