import React, {useState} from "react";
import ErrorAlert from "../Helper/ErrorAlert";
import SuccessAlert from "../Helper/SuccessAlert";
import './Profile.scss'
import AuthService from "../services/auth.service";
import http from "../http-common";
import authHeader from "../services/auth-header";

function Profile() {
    const [user] = useState(AuthService.getCurrentUser())
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordTwo, setNewPasswordTwo] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    function validateCredentials() {

        const postPassword = async (username, passwordOld, passwordNew) => {
            let error;
            await http.post(`/api/auth/newPassword`, {
                    username: username,
                    passwordOld: passwordOld,
                    passwordNew: passwordNew
                }, {headers: authHeader()}
            ).catch((err) => error = err)
            if (error) {
                setErrorMessage(error.response.data.message)
                setShowError(true)
                setShowSuccess(false)
            } else {
                setShowSuccess(true)
                setShowError(false)
            }
        }

        if (newPassword === newPasswordTwo) {
            postPassword(user.username, password, newPassword).catch((err) => console.log(err))
        } else {
            setShowError(true)
            setShowSuccess(false)
            setErrorMessage("Sisestatud paroolid ei ühtinud")
        }
        setPassword('')
        setNewPassword('')
        setNewPasswordTwo('')
    }

    return (
        <main id='profile-edit-page'>
            <h2>Muuda parooli</h2>
            <label className="strong-label">Kasutaja: {user.username}</label>
            <ErrorAlert className={showError ? "show-error" : "hide-error"} message={errorMessage} open={showError}
                        setOpen={setShowError}/>
            <SuccessAlert className={showSuccess ? "show-error" : "hide-error"} open={showSuccess}
                          setOpen={setShowSuccess}/>
            <div className="change-password">
                <input value={password} onChange={e => setPassword(e.target.value)} type='password'
                       placeholder='Praegune parool'/>
                <input value={newPassword} onChange={e => setNewPassword(e.target.value)} type='password'
                       placeholder='Uus parool'/>
                <input value={newPasswordTwo} onChange={e => setNewPasswordTwo(e.target.value)} type='password'
                       placeholder='Korda uut parooli'/>
            </div>
            <button className="change-password-button regularButton" onClick={() => validateCredentials()}>Salvesta
                muudatused
            </button>
        </main>)
}


export default Profile;