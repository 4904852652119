import React, {useState, useEffect} from 'react';
import './MarkAttendance.scss';
import PersonAttendance from './PersonAttendance';
import {compareValues} from '../Helper/functions.js';
import {Link, useParams} from 'react-router-dom';
import http from '../http-common';
import authHeader from '../services/auth-header';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

// TODO delete console.log
function MarkAttendance() {
    const [allPeople, setAllPeople] = useState([])
    const [event, setEvent] = useState()
    const [participants, setParticipants] = useState([]);

    const [bikes, setBikes] = useState()
    const [fuelTypes, setFuelTypes] = useState()
    const [instructors, setInstructors] = useState()
    const {eventId} = useParams();

    function getGroups() {
        let groups = participants.map(({group}) => group).filter((v, i, a) => a.indexOf(v) === i);
        groups.sort()
        if (groups.includes('Lisatud')) {
            groups = groups.filter(group => group !== 'Lisatud')
            groups.push('Lisatud')
        }
        return groups
    }

    useEffect(() => {
        const getData = async () => {
            let eventInfo = await http.get(`/api/sessions/${eventId}`, {headers: authHeader()})
            eventInfo = eventInfo.data
            let addablePeople = await http.get('/api/addableParticipants', {headers: authHeader()})
            addablePeople = addablePeople.data.map(person => ({
                ...person,
                name: `${person.first_name} ${person.last_name}`
            }))
            let participantsRequest = await http.get(`/api/sessionParticipants/${eventId}`, {headers: authHeader()})
            participantsRequest = participantsRequest.data.result
            let savedInfo = await http.get(`/api/savedSessionInfo/${eventId}`, {headers: authHeader()})
            savedInfo = savedInfo.data.result
            let instructors = await http.get(`/api/sessionInstructors`, {headers: authHeader()})
            instructors = instructors.data
            instructors.unshift({value: null, label: 'Enda hooldus'})
            instructors = instructors.map((item, id) => ({id: id, ...item}))
            let bikes = await http.get(`/api/sessionBikes`, {headers: authHeader()})
            bikes = bikes.data
            bikes.unshift({id: -1, name: '', fee: 0})
            let fuelTypes = await http.get(`/api/sessionFuel`, {headers: authHeader()})
            fuelTypes = fuelTypes.data
            fuelTypes.unshift({id: -1, name: '', fee: 0})

            function findInfo(id) {

                let filtered = savedInfo.filter(({id_participant}) => id_participant === id)
                if (filtered.length === 1) {
                    const person = filtered[0]
                    return {
                        ...person,
                        fuel_name: (person.fuel_name === '' ? '' : person.fuel_name),
                        bike_name: (person.bike_name === '' ? '' : person.bike_name),
                        maintenance: 'Enda hooldus',
                        attending: true
                    }
                }
                return {}
            }

            const groupIds = participantsRequest.map(({id_participant}) => id_participant)

            savedInfo.filter(({id_participant}) => !groupIds.includes(id_participant))
                .forEach(addedPerson =>
                    participantsRequest.push({
                            ...addedPerson,
                            group: 'Lisatud',
                            attending: true
                        }
                    ))
            participantsRequest = participantsRequest.map(participant => ({
                group: 'Lisatud',
                bike_name: '',
                fuel_name: '',
                id_instructor: null,
                fuel_quantity: 1,
                attending: false,
                ...participant,
                ...findInfo(participant.id_participant)
            })).map(participant => (
                {
                    ...participant,
                    maintenance: instructors.find(instructor => instructor.value === participant.id_instructor).label,
                    fuel_quantity: participant.fuel_quantity === 0 ? 1 : participant.fuel_quantity,
                }))

            // console.log('eventRequest', eventInfo)
            // console.log('allPeopleRequest', addablePeople)
            // console.log('participants', participantsRequest)
            // console.log('savedInfo', savedInfo)
            // console.log('instructors', instructors)
            // console.log('bikes', bikes)
            // console.log('fuelTypes', fuelTypes)
            // console.log('participants with data', participantsRequest)

            setBikes(bikes)
            setFuelTypes(fuelTypes)
            setInstructors(instructors)
            setAllPeople(addablePeople)
            setParticipants(participantsRequest)
            setEvent(eventInfo)
        }
        getData();
    }, [eventId]);

    function addPerson(person) {
        if (person) {
            const {id, first_name, last_name} = person
            setParticipants([
                ...participants,
                {
                    group: 'Lisatud',
                    id_participant: id,
                    first_name: first_name,
                    last_name: last_name,
                    id_instructor: null,
                    attending: true,
                    bike_name: '',
                    fuel_name: '',
                    maintenance: 'Enda hooldus',
                    fuel_quantity: 1,
                }]
            )
        }
    }

    function update(id) {
        return (type, value) =>

            setParticipants(participants.map(participant => {
                    if (participant.id_participant === id) participant[type] = value;
                    return participant
                })
            )
    }

    // example usages
    /*
    http.post(`/api/deleteParticipantSession`, {
        notAttending: [
            {
                id_participant: 1,
                id_session: 4,
            }, {
                id_participant: 2,
                id_session: 4,
            },
        ]
    }, {headers: authHeader()}).then(result => console.log(result))

    http.post(`/api/ParticipantSession`, {
        attending: [
            {
                id_participant: 1,
                id_session: 4,
                id_instructor: 1,
                fuel_quantity: 2,
                fuel_name: '',
                fuel_fee: 5,
                bike_name: '',
                bike_fee: 0
            }, {
                id_participant: 2,
                id_session: 4,
                id_instructor: 1,
                fuel_quantity: 2,
                fuel_name: '',
                fuel_fee: 5,
                bike_name: '',
                bike_fee: 0
            },
        ]
    }, {headers: authHeader()}).then(result => console.log(result))
     */
    function saveChanges(event) {
        let deleted = false
        let saved = false

        function successfulSave() {
            saved = true
        }

        function successfulDelete() {
            deleted = true
        }

        const deletedValues = participants
            .filter(({attending}) => !attending)
            .filter((item, index, self) => index === self.findIndex((t) => (t.id_participant === item.id_participant)))
            .map(({id_participant}) => ({id_participant: id_participant, id_session: eventId}))
        const updatedValues = participants
            .filter(({attending}) => attending)
            .filter((item, index, self) => index === self.findIndex((t) => (t.id_participant === item.id_participant)))
            .map(({
                      id_participant,
                      fuel_quantity,
                      fuel_name,
                      fuel_fee,
                      bike_name,
                      bike_fee,
                      maintenance,
                      id_instructor
                  }) => ({
                id_participant: id_participant,
                id_session: parseInt(eventId),
                id_instructor: instructors.find(instructor => instructor.label === maintenance).value,
                fuel_quantity: fuel_name === '' ? 0 : fuel_quantity,
                fuel_name: fuel_name,
                fuel_fee: fuel_name === '' ? 0 : fuelTypes.find(fuel => fuel.name === fuel_name).fee,
                bike_name: bike_name,
                bike_fee: bike_name === '' ? 0 : bikes.find(bike => bike.name === bike_name).fee
            }))

        // console.log('allPeople', allPeople)
        // console.log('participants', participants)
        console.log('deleted people', deletedValues)
        console.log('updated people', updatedValues)

        http.post(`/api/deleteParticipantSession`, {
            notAttending: deletedValues
        }, {headers: authHeader()}).then(result => console.log(result)).then(successfulDelete())
        http.post(`/api/ParticipantSession`, {
            attending: updatedValues
        }, {headers: authHeader()}).then(result => console.log(result)).then(successfulSave())

        console.log(saved, deleted)
        if (!(deleted && saved)) { // TODO wait for promise to finish (add async and await), this is evaluated before post returns error
            alert("tekkis viga salvestamisel") // TODO improve
            event.preventDefault()
        }
    }

    function cancelEditing(event) {
        !window.confirm('Tehtud muudatusi ei salvestata') && event.preventDefault() // TODO improve
    }

    function formatTime(time) {
        const t = new Date(time).toTimeString().split(' ')[0].split(':')
        return `${t[0]}:${t[1]}`
    }

    function getAddablePeople() {
        return allPeople.filter(person => !participants.some(({id_participant}) => id_participant === person.id))
    }

    return (
        <div className='main'>
            {event && <>
                <h2>{event.startDate.slice(8, 10)}.{event.startDate.slice(5, 7)}, {formatTime(event.startDate)} - {formatTime(event.endDate)} {event.title}</h2>
                <div id='person_table'>
                    {getGroups().map(group =>
                        <ExpandableGroup key={group} name={group}>
                            {participants
                                .filter(participant => participant.group === group)
                                .sort(compareValues('name')).map((participant) =>
                                    <PersonAttendance
                                        usingEquipment={event.type ? event.type.equipment : true}
                                        key={`${participant.group} ${participant.id_participant}`}
                                        person={participant}
                                        update={update(participant.id_participant)}
                                        bikes={bikes}
                                        fuelTypes={fuelTypes}
                                        instructors={instructors}
                                    />)}
                        </ExpandableGroup>)}
                </div>
                <AddPerson addPerson={addPerson} addablePeople={getAddablePeople()}/>
                <Link to={'/planeerija'}>
                    <button className='regularButton save saveTraining' onClick={e => saveChanges(e)}>Salvesta trenn
                    </button>
                    <button className='regularButton remove back' onClick={e => cancelEditing(e)}>Tühista</button>
                </Link>
            </>}
        </div>
    );
}

function AddPerson({addPerson, addablePeople}) {
    const [person, setPerson] = useState();

    function handleAdd(person) {
        addPerson(person)
        setPerson(undefined)
    }

    return (
        <div id='add_person'>
            {/*<input type='text' placeholder='Otsi nime ...' value={name} onChange={e => handleSearchPerson(e.target.value)}/>*/}
            <Autocomplete
                key={addablePeople}
                id="combo-box"
                options={addablePeople}
                onChange={(event, newValue) => setPerson(newValue)}
                getOptionLabel={person => person.name}
                forcePopupIcon={false}
                size="small"
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Otsi nime ..."
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon/>
                                </InputAdornment>
                            )
                        }}
                    />
                }
            />
            <button className='regularButton' onClick={() => handleAdd(person)}>Lisa osaleja</button>
        </div>
    );
}


function ExpandableGroup({children, name}) {
    const [expand, setExpand] = useState(name === 'Lisatud');

    return (
        <div className='expandable_row'>
            <div className={expand ? 'expanded' : ''}>
                <button className='group_name' onClick={() => setExpand(!expand)}>{name}</button>
                {expand && children}
            </div>
        </div>
    );
}

export default MarkAttendance;
