import React, {useState} from "react";
import './EditAccount.scss';
import http from "../http-common";
import authHeader from "../services/auth-header";
import ErrorAlert from "../Helper/ErrorAlert";

function EditAccount({save, cancel, userInfo}) {
    const isNewUser = Object.keys(userInfo).length === 0;
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const postData = async (action, user) => {
        let error;
        if (action === 'add') {
            const response = await http.post(`/api/accounts/`, user, {headers: authHeader()}).catch((err) => error = err)
            console.log(response)
        } else if (action === 'update') {
            const response = await http.put(`/api/accounts/${user.id}`, user, {headers: authHeader()}).catch((err) => error = err)
            console.log(response);
        }
        if (error) {
            setErrorMessage(error.response.data.errors.map(e => e.message).join(', '))
            setShowError(true)
        } else {
            setShowError(false)
            save(user)
        }
    }

    const [user, setUser] = useState({
        ...{
            username: '',
            name: '',
            email: '',
            password: '',
            repeatedPassword: '',
            admin: false,
            active: true
        }, ...userInfo
    });

    function set(key, value) {
        setUser({...user, [key]: value})
    }

    function saveUser() {
        if (isNewUser) {
            if (user.password === user.repeatedPassword) {
                setShowError(false)
                postData('add', user).catch((err) => console.log(err))
            } else {
                setErrorMessage("Paroolid ei ühti")
                setShowError(true)
            }
        } else {
            postData('update', user).catch((err) => console.log(err))
        }
    }

    return (
        <div id="myModal" className="modal">
            <div className="modal-content">
                <div className="main">
                    <h2 className={isNewUser ? "" : "hide"}>Uus kasutaja</h2>
                    <h2 className={isNewUser ? "hide" : ""}>Muuda kasutajat</h2>
                    <ErrorAlert className={showError ? "show-error" : "hide-error"} message={errorMessage}
                                open={showError} setOpen={setShowError}
                    />
                    <form className='user_fields'>
                        <label htmlFor='username'>Kasutajanimi</label>
                        <input value={user.username} name='username' onChange={e => set('username', e.target.value)}
                               type='text'/>
                        <label htmlFor='name'>Nimi</label>
                        <input value={user.name} name='name' onChange={e => set('name', e.target.value)} type='text'/>
                        <label htmlFor='email'>Meiliaadress</label>
                        <input value={user.email} name='email' onChange={e => set('email', e.target.value)}
                               type='text'/>
                        <label className={isNewUser ? "" : "hide"} htmlFor='password'>Parool</label>
                        <input className={isNewUser ? "" : "hide"} value={user.password} name='password'
                               onChange={e => set('password', e.target.value)}
                               type='password'/>
                        <label className={isNewUser ? "" : "hide"} htmlFor='repeatedPassword'>Korda parooli</label>
                        <input className={isNewUser ? "" : "hide"} value={user.repeatedPassword} name='repeatedPassword'
                               onChange={e => set('repeatedPassword', e.target.value)} type='password'/>
                        <label htmlFor='admin'>Administraator</label>
                        <Switch
                            userValue={user.admin}
                            value={'admin'}
                            set={set}>
                        </Switch>
                        <label htmlFor='active'>Aktiivne</label>
                        <Switch
                            userValue={user.active}
                            value={'active'}
                            set={set}>
                        </Switch>
                    </form>
                    <div className="addUserButtons">
                        <button className="regularButton save" onClick={() => saveUser()} id='save'>Salvesta</button>
                        <button className="regularButton remove" onClick={() => cancel()} id='cancel'>Tühista</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Switch({userValue, value, set}) {
    return (
        <label className="switch">
            <input checked={userValue} name='active' onChange={() => set(value, !userValue)}
                   type='checkbox'/>
            <span className="slider round"/>
        </label>
    );
}

export default EditAccount;
