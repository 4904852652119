import React, {useState} from 'react';
import './app.scss';
import Header from "./Header/Header";
import Login from "./Login/Login";
import {Switch} from "react-router-dom";

function App() {
    const [username, setUser] = useState({username: '', isAdmin: false});

    return (
        <Switch>
            <Login setUser={setUser}>
                <Header setUser={setUser}/>
            </Login>
        </Switch>
    );
}
export default App;
