import React, {useState, useRef, useCallback, useEffect} from 'react';
import authHeader from '../services/auth-header';
import './Data.scss'
import Paper from '@material-ui/core/Paper';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditColumn,
    TableEditRow,
    PagingPanel,
    Toolbar,
    ExportPanel,
    SearchPanel,
    TableBandHeader,
} from '@devexpress/dx-react-grid-material-ui';
import {
    EditingState,
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging, SearchState, IntegratedFiltering, DataTypeProvider
} from '@devexpress/dx-react-grid';
import {GridExporter} from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from "@material-ui/core/MenuItem";
import {Select, Input} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import AddBoxIcon from '@material-ui/icons/AddBox';
import http from "../http-common";
import ErrorAlert from "../Helper/ErrorAlert";
import TextField from "@material-ui/core/TextField";

function Data() {
    const [participants, setParticipants] = useState([]);
    const [bikes, setBikes] = useState([]);
    const [fuel, setFuel] = useState([]);
    const [locations, setLocations] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedTable, setSelectedTable] = useState('participants')
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    function catchError(err, message) {
        setShowError(true)
        setErrorMessage(message)
        console.error(err);
    }

    const getData = async (name, setData) => {
        const response = await http.get(`/api/${name}`, {headers: authHeader()})
        setData(response.data)
        console.log(response.data);
    }

    useEffect(() => {
        getData('participants', setParticipants).catch((err) => catchError(err, "andmeid ei leitud või puudub andmetele ligipääsuõigus"))
        getData('bikes', setBikes).catch((err) => catchError(err, "andmeid ei leitud või puudub andmetele ligipääsuõigus"))
        getData('fuel', setFuel).catch((err) => catchError(err, "andmeid ei leitud või puudub andmetele ligipääsuõigus"))
        getData('locations', setLocations).catch((err) => catchError(err, "andmeid ei leitud või puudub andmetele ligipääsuõigus"))
        getData('types', setTypes).catch((err) => catchError(err, "andmeid ei leitud või puudub andmetele ligipääsuõigus"))
    }, []);

    const peopleTableFormat = [
        //{name: 'id', title: 'ID'},
        {name: 'first_name', title: 'Eesnimi'},
        {name: 'last_name', title: 'Perenimi'},
        {name: 'bike_cc', title: 'Tsikli suurus'},
        {name: 'parent1_name', title: 'Nimi'},
        {name: 'parent1_email', title: 'Meiliaadress'},
        {name: 'parent1_phone', title: 'Telefon'},
        {name: 'parent1_invoice', title: 'Arve'},
        {name: 'parent2_name', title: 'Nimi'},
        {name: 'parent2_email', title: 'Meiliaadress'},
        {name: 'parent2_phone', title: 'Telefon'},
        {name: 'parent2_invoice', title: 'Arve'}
    ]

    const [peopleTableColumnBands] = useState([
        {
            title: '1. vanem',
            children: [
                {columnName: 'parent1_name'},
                {columnName: 'parent1_email'},
                {columnName: 'parent1_phone'},
                {columnName: 'parent1_invoice'},
            ],
        },
        {
            title: '2. vanem',
            children: [
                {columnName: 'parent2_name'},
                {columnName: 'parent2_email'},
                {columnName: 'parent2_phone'},
                {columnName: 'parent2_invoice'},
            ],
        },
    ]);

    const [otherColumnBands] = useState([]);

    const bikeTableFormat = [
        //{name: 'id', title: 'ID'},
        {name: 'name', title: 'Nimi'},
        {name: 'cc', title: 'Kubatuur (cc)'},
        {name: 'fee', title: 'Renditasu (€)'}
    ]

    const fuelTableFormat = [
        //{name: 'id', title: 'ID'},
        {name: 'name', title: 'Nimi'},
        {name: 'fee', title: 'Renditasu (€)'}
    ];

    const locationTableFormat = [
        //{name: 'id', title: 'ID'},
        {name: 'name', title: 'Nimi'},
        {name: 'address', title: 'Aadress'}
    ];

    const typeTableFormat = [
        //{name: 'id', title: 'ID'},
        {name: 'name', title: 'Nimi'},
        {name: 'equipment', title: 'Varustuse rent'},
        {name: 'participation_fee', title: 'Osalemistasu (€)'},
        {name: 'maintenance_fee', title: 'Varustuse hooldustasu (€)'},
    ];


    return (
        <main className="data-main">
            <div className="table-selector five-buttons">
                <button
                    className={selectedTable === 'participants' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("participants")}
                >
                    Osalejad
                </button>
                <button
                    className={selectedTable === 'bikes' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("bikes")}
                >
                    Tsiklid / Quadid
                </button>
                <button
                    className={selectedTable === 'fuel' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("fuel")}
                >
                    Kütus
                </button>
                <button
                    className={selectedTable === 'locations' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("locations")}
                >
                    Asukohad
                </button>
                <button
                    className={selectedTable === 'session-types' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("session-types")}
                >
                    Trennitüübid
                </button>
            </div>
            <ErrorAlert className={showError ? "show-error" : "hide-error"} message={errorMessage}
                        open={showError} setOpen={setShowError}
            />
            <div className={selectedTable === 'participants' ? "people-table" : "hide-table"}>
                <h2>Osalejad</h2>
                <DataTable data={participants} columns={peopleTableFormat} setData={setParticipants}
                           tableName={"participants"} columnBands={peopleTableColumnBands} setShowError={setShowError}
                           catchError={catchError} set={setParticipants}/>
            </div>
            <div className={selectedTable === 'bikes' ? "bike-table" : "hide-table"}>
                <h2>Tsiklid / Quadid</h2>
                <DataTable data={bikes} columns={bikeTableFormat} setData={setBikes} tableName={"bikes"}
                           columnBands={otherColumnBands} setShowError={setShowError}
                           catchError={catchError} set={setBikes}/>
            </div>
            <div className={selectedTable === 'fuel' ? "fuel-table" : "hide-table"}>
                <h2>Kütus</h2>
                <DataTable data={fuel} columns={fuelTableFormat} setData={setFuel} tableName={"fuel"}
                           columnBands={otherColumnBands} setShowError={setShowError}
                           catchError={catchError} set={setFuel}/>
            </div>
            <div className={selectedTable === 'locations' ? "location-table" : "hide-table"}>
                <h2>Trennide asukohad</h2>
                <DataTable data={locations} columns={locationTableFormat} setData={setLocations}
                           tableName={"locations"} columnBands={otherColumnBands} setShowError={setShowError}
                           catchError={catchError} set={setLocations}/>
            </div>
            <div className={selectedTable === 'session-types' ? "session-type-table" : "hide-table"}>
                <h2>Trennitüübid</h2>
                <DataTable data={types} columns={typeTableFormat} setData={setTypes} tableName={"types"}
                           columnBands={otherColumnBands} setShowError={setShowError}
                           catchError={catchError} set={setTypes}/>
            </div>
        </main>
    )
}

// https://devexpress.github.io/devextreme-reactive/react/grid/
function DataTable({data, columns, setData, tableName, columnBands, setShowError, catchError, set}) {
    const [editingRowIds, setEditingRowIds] = useState([])
    const [rowChanges, setRowChanges] = useState({})
    const [addedRows, setAddedRows] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageSizes] = useState([5, 10, 50, 0]);
    const [booleanColumns] = useState(['equipment', 'parent1_invoice', 'parent2_invoice']);
    const [numericColumns] = useState(['fee', 'bike_cc', 'cc', 'participation_fee', 'maintenance_fee']);

    // Stuff for export:
    const exporterRef = useRef(GridExporter);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]); //

    const changeAddedRows = (value) => {
        const initialized = value.map(row => (Object.keys(row).length ? row : {
            parent1_invoice: false,
            parent2_invoice: false,
        }));
        setAddedRows(initialized);
    }

    const commitChanges = ({added, changed, deleted}) => {

        const getData = async (name, setData) => {
            const response = await http.get(`/api/${name}`, {headers: authHeader()})
            setData(response.data)
        }

        const postData = async (tableName, action, target, id) => {
            if (action === 'add') {
                await http.post(`/api/${tableName}/`, target, {headers: authHeader()})
            } else if (action === 'update') {
                await http.put(`/api/${tableName}/${id}`, target, {headers: authHeader()})
            } else if (action === 'delete') {
                await http.delete(`/api/${tableName}/${id}`, {headers: authHeader()})
            }
            getData(tableName, set).catch((err) => console.log(err))
            setShowError(false)
        }

        if (added) {
            const targetRow = added[0]
            postData(tableName, 'add', targetRow).catch((err) => catchError(err, "lisatud andmeid ei salvestatud (kontrolli, et kõik väljad oleksid täidetud)"))
        }
        if (changed) {
            const targetId = parseInt(Object.keys(changed))
            const targetRow = Object.values(changed)[0]
            postData(tableName, 'update', targetRow, targetId).catch((err) => catchError(err, "muudatusi ei salvestatud (kontrolli, et kõik väljad oleksid täidetud)"))
        }
        if (deleted) {
            const targetRow = deleted[0]
            postData(tableName, 'delete', targetRow, deleted).catch((err) => catchError(err, "andmeid ei kustutatud"))
        }
    }

    return (
        <Paper>
            <Grid
                rows={data}
                columns={columns}
                getRowId={getRowId}
            >
                <BooleanTypeProvider
                    for={booleanColumns}
                />
                <NumberTypeProvider
                    for={numericColumns}
                />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging/>
                <EditingState
                    editingRowIds={editingRowIds}
                    onEditingRowIdsChange={setEditingRowIds}
                    rowChanges={rowChanges}
                    onRowChangesChange={setRowChanges}
                    addedRows={addedRows}
                    onAddedRowsChange={changeAddedRows}
                    onCommitChanges={commitChanges}
                    columnExtensions={[{columnName: 'id', editingEnabled: false}]}
                />
                <SortingState
                    defaultSorting={[
                        {columnName: 'firstname', direction: 'asc'},
                        {columnName: 'name', direction: 'asc'}]}
                />
                <IntegratedSorting/>
                <SearchState/>
                <IntegratedFiltering/>
                <Table
                    columnExtensions={[
                        {columnName: 'id', width: 60},
                        {columnName: 'first_name', width: 110},
                        {columnName: 'last_name', width: 110},
                        {columnName: 'bike_cc', width: 130},
                        {columnName: 'parent1_name', minWidth: 120},
                        {columnName: 'parent2_name', minWidth: 120},
                        {columnName: 'parent1_email', minWidth: 190},
                        {columnName: 'parent2_email', minWidth: 190},
                        {columnName: 'parent1_phone', minWidth: 110},
                        {columnName: 'parent2_phone', minWidth: 110},
                        {columnName: 'parent1_invoice', minWidth: 80},
                        {columnName: 'parent2_invoice', minWidth: 80},
                        {columnName: 'equipment', minWidth: 150}
                    ]}
                    messages={{noData: "Andmed puuduvad"}}
                />
                <TableHeaderRow showSortingControls/>
                <TableEditRow cellComponent={EditCell}/>
                <TableEditColumn
                    showAddCommand={!addedRows.length}
                    showEditCommand
                    showDeleteCommand
                    commandComponent={Command}
                />
                <PagingPanel
                    pageSizes={pageSizes}
                    messages={{showAll: "Kõik", rowsPerPage: "Ridu lehel"}}
                />
                <Toolbar/>
                <SearchPanel messages={{searchPlaceholder: "Otsi..."}}/>
                <ExportPanel startExport={startExport}
                             messages={{showExportMenu: "Ekspordi", exportAll: "Ekspordi kõik selle tabeli andmed"}}/>
                <TableBandHeader
                    columnBands={columnBands}
                />
            </Grid>
            <GridExporter
                ref={exporterRef}
                columns={columns}
                rows={data}
                onSave={onSave}
            />
        </Paper>
    )
}

const getRowId = row => row.id;

const AddButton = ({onExecute}) => (
    <IconButton className="person-add" onClick={onExecute} title="Lisa uus rida">
        <AddBoxIcon/>
    </IconButton>
);

const EditButton = ({onExecute}) => (
    <IconButton onClick={onExecute} title="Muuda rida">
        <EditIcon/>
    </IconButton>
);

const DeleteButton = ({onExecute}) => (
    <IconButton
        className="person-remove"
        onClick={() => {
            if (window.confirm('Kas olete kindel, et tahate selle rea kustutada?')) {
                onExecute();
            }
        }}
        title="Kustuta rida"
    >
        <DeleteIcon/>
    </IconButton>
);

const CommitButton = ({onExecute}) => (
    <IconButton onClick={onExecute} title="Salvesta muudatused">
        <SaveIcon/>
    </IconButton>
);

const CancelButton = ({onExecute}) => (
    <IconButton className="person-remove" onClick={onExecute} title="Tühista muudatused">
        <CancelIcon/>
    </IconButton>
);

const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton,
};

const EditCell = (props) => {
    return <TableEditRow.Cell {...props} />;
};

const Command = ({id, onExecute}) => {
    const CommandButton = commandComponents[id];
    return (
        <CommandButton
            onExecute={onExecute}
        />
    );
};

const BooleanEditor = ({value, onValueChange}) => (
    <Select
        input={<Input/>}
        value={value ? 'Jah' : 'Ei'}
        onChange={event => onValueChange(event.target.value === 'Jah')}
        style={{width: '100%'}}
    >
        <MenuItem value="Jah">
            Jah
        </MenuItem>
        <MenuItem value="Ei">
            Ei
        </MenuItem>
    </Select>
);

const BooleanTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={({value}) => <label>{value ? 'Jah' : 'Ei'}</label>}
        editorComponent={BooleanEditor}
        {...props}
    />
);

const NumberEditor = ({value, onValueChange}) => (
    <TextField
        id="standard-number"
        variant="standard"
        type="number"
        InputLabelProps={{
            shrink: true,
        }}
        defaultValue={value}
        rules={{required: true}}

        onChange={event => onValueChange(value = event.target.value)}
        error={value === ""}
    />
);

const NumberTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={({value}) => value}
        editorComponent={NumberEditor}
        {...props}
    />
);

const onSave = (workbook) => {
    workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: "application/octet-stream"}), "kokkuvote.csv");
    });
};

export default Data;
