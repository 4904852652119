import React, {useEffect, useState} from 'react';
import './Groups.scss';
import GroupEdit from "./GroupEdit";
import http from "../http-common";
import authHeader from '../services/auth-header';

/* Main component of groups page that holds all the other components */
function Groups() {
    const [allGroups, setAllGroups] = useState([])
    const [selected, setSelected] = useState(-1);
    const [inputValue, setInputValue] = useState("");

    const getGroupData = async (name, set) => {
        http.get(`/api/${name}`, {headers: authHeader()}).then(result => set(result.data))
        //http.get(`/api/${name}`).then(result => console.log(result.data)) // -
    }

    useEffect(() => {
        getGroupData('groups', setAllGroups)
    }, []);


    function validateInput(value) {
        setInputValue(value.replace(/[^A-Za-zÕÄÖÜõäöü0-9 ]/g, ''))
    }

    function addGroup() {
        http.post(`/api/addGroup/`, {name: inputValue}, {headers: authHeader()}).then(result => setSelected(result.data.id))
        getGroupData('groups', setAllGroups)
        setInputValue("")
    }

    function deleteGroup() {
        http.delete(`/api/groups/${selected}`, {headers: authHeader()})
        setAllGroups(allGroups.filter(group => group.id !== selected))
        setSelected(-1)
    }

    function backFromEditing() {
        getGroupData('groups', setAllGroups)
        setSelected(-1)
    }

    return (
        <>
            {selected === -1
                ? // List groups view
                <main>
                    {/* New group text field + button */}
                    <span className='create_group'>
                        <input type='text' placeholder='Sisesta uue grupi nimi ...' value={inputValue}
                               onChange={e => validateInput(e.target.value)}/>
                        <button className="regularButton" onClick={() => addGroup()}>Loo grupp</button>
                    </span>
                    <div className='flex'>
                        {allGroups && allGroups.map(group =>
                            <Group
                                key={group.id}
                                group={group}
                                setSelectedGroup={setSelected}
                            />)}
                    </div>
                </main>
                : // Edit group view
                allGroups && allGroups.filter(group => group.id === selected).map(group =>
                    <div key={group.name} className='main'>
                        <h2>Grupp {group.name.toLowerCase()}</h2>
                        <GroupEdit
                            key={group.id}
                            attendingGroup={group.participants}
                            groupId={selected}
                            doneEditing={() => backFromEditing()}
                            deleteGroup={() => deleteGroup()}
                        >
                        </GroupEdit>
                    </div>
                )
            }
        </>
    );
}


/* Component for individual group (button) with its name, number of members and delete button */
function Group({group, setSelectedGroup}) {
    return (
        <span className='group'>
                <span className='group_row'>
                    <button key={group.id} id='group_button' onClick={() => setSelectedGroup(group.id)}>
                        <span>
                            <label id='group_name'>{group.name}</label>
                            <label id='group_members'> ({group.participants.length}) </label>
                        </span>
                    </button>
                </span>
        </span>
    );
}

export default Groups;
