import React, {useEffect, useState} from 'react';
import ResponsiveMenu from "./ResponsiveMenu";
import {FaBars, FaTimes} from 'react-icons/fa';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import './Header.scss';
import Planning from "../Planning/Planning";
import Data from "../Data/Data";
import Groups from "../Groups/Groups";
import Accounts from "../Accounts/Accounts";
import Summary from "../Summary/Summary";
import Profile from "../Profile/Profile";
import {useLocation, Route, Link} from "react-router-dom";
import AuthService from "../services/auth.service";


const routes = [
    {
        id: 1,
        text: 'Planeerija',
        path: '/planeerija',
        component: Planning
    },
    {
        id: 2,
        text: 'Andmed',
        path: '/andmed',
        component: Data
    },
    {
        id: 3,
        text: 'Grupid',
        path: '/grupid',
        component: Groups
    },
    {
        id: 4,
        text: 'Kasutajad',
        path: '/kasutajad',
        component: Accounts
    },
    {
        id: 5,
        text: 'Kokkuvõtted',
        path: '/kokkuvõtted',
        component: Summary
    },
    {
        id: 7,
        text: 'Profiil',
        path: '/profiil',
        component: Profile
    }
];

function Header({setUser}) {
    function onClickLogout() {
        AuthService.logout();
        setUser({username: '', admin: false});
    }

    return (
        <div className="header">
            <header>
                <Link to={'/planeerija'} key={1}>
                    <img className="header-left" src={require("../resources/emsa_logo.png")}
                         alt={"Eesti motospordi akadeemia logo"}/>
                </Link>
                <ResponsiveMenu
                    menuOpenButton={<FaBars size={30}/>}
                    menuCloseButton={<FaTimes size={30}/>}
                    changeMenuOn="1024px"
                    largeMenuClassName="large-menu"
                    smallMenuClassName="small-menu"
                    menu={ButtonList()}
                />
                <div className="header-right">
                    <Link to={'/profiil'} key={7}>
                        <button className="user-button">
                            <AccountBoxSharpIcon fontSize='large'/>
                        </button>
                    </Link>
                    <button className="logout-Button" onClick={() => onClickLogout()}>
                        <ExitToAppSharpIcon fontSize='large'/>
                    </button>
                </div>
            </header>
            <hr className="header-line"/>
            <Route exact path="/" component={Planning}/>
            {routes.map(({path, component}, id) => <Route path={path} component={component} key={id}/>)}
        </div>
    );
}

function ButtonList() {
    const location = useLocation();
    const [end, setEnd] = useState(1)

    useEffect(() => {
        if (AuthService.getCurrentUser().admin === true) {
            setEnd(5)
        }
    }, []);


    return (
        <div className="navbar">
            {routes.slice(0, end).map(({id, text, path}) => (
                <Link to={path} key={id}>
                    <button key={id} className={location.pathname === path ? 'is_active' : ''}>
                        {text}
                    </button>
                </Link>
            ))}
        </div>
    )
}

export default Header;