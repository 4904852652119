import React, {useRef, useCallback, useState, useEffect} from 'react';
import './Summary.scss'
import Paper from '@material-ui/core/Paper';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    ExportPanel, TableFixedColumns, SearchPanel, TableSummaryRow
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    IntegratedSorting, SearchState, IntegratedFiltering, DataTypeProvider, SummaryState, IntegratedSummary,
    PagingState, IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {GridExporter} from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import http from "../http-common";
import {number} from "prop-types";
import authHeader from "../services/auth-header";

function Summary() {
    const [selectedTable, setSelectedTable] = useState('participants')
    const [participantSummaryData, setParticipantSummaryData] = useState([])
    const [instructorTypeSummaryData, setInstructorTypeSummaryData] = useState([])
    const [instructorSummaryData, setInstructorSummaryData] = useState([])
    const [maintenanceSummaryData, setMaintenanceSummaryData] = useState([])
    const [bikeSummaryData, setBikeSummaryData] = useState([])
    const [fuelSummaryData, setFuelSummaryData] = useState([])
    const [detailTableColumns, setDetailTableColumns] = useState([])
    const [detailTableColumns2, setDetailTableColumns2] = useState([])
    const [detailTableData, setDetailTableData] = useState([])
    const [detailTableData2, setDetailTableData2] = useState([])
    const [period, setPeriod] = useState({
        start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10),
        end: new Date().toISOString().slice(0, 10)
    })

    useEffect(() => {
        const getDetailColums = async (name, set) => {
            const response = await http.get(`/api/${name}`, {headers: authHeader()})
            set([{name: 'participant_name', title: "Osaleja"}].concat(response.data.result));
        }

        const getDetailedData = async (set) => {
            let bikeNames;
            let instructors;
            const postParams = {
                bikeNames: [],
                instructors: [],
                period: period
            }

            await http.get(`/api/bikeNames`, {headers: authHeader()}).then(result => bikeNames = result.data.map(bike => bike.name))
            await http.get(`/api/instructorNames`, {headers: authHeader()}).then(result => instructors = result.data)
            if (bikeNames && instructors) {
                postParams.bikeNames = bikeNames
                postParams.instructors = instructors
                await http.post(`/api/detailSummary`, postParams, {headers: authHeader()}).then(result => set(result.data.result));
            }
        }

        const getDetailedData2 = async (set) => {
            let instructors;
            const postParams = {
                instructors: [],
                period: period
            }

            await http.get(`/api/instructorNames`, {headers: authHeader()}).then(result => instructors = result.data)
            if (instructors) {
                postParams.instructors = instructors
                await http.post(`/api/detailedData2`, postParams, {headers: authHeader()}).then(result => set(result.data.result));
            }
        }

        const getSummaryData = async (path, set) => {
            await http.post(`/api/${path}`, period, {headers: authHeader()}).then(result =>
                set(
                    Object.values(convertIntObj(result.data.result))
                )
            );
        }

        // dumb function for converting strings into int
        // because sql sum() function gives column values as string, but table requires integer for calculating column sum
        function convertIntObj(obj) {
            const res = {}
            for (const key in obj) {
                res[key] = {};
                for (const prop in obj[key]) {
                    const parsed = parseInt(obj[key][prop], 10);
                    res[key][prop] = isNaN(parsed) ? obj[key][prop] : parsed;
                }
            }
            return res;
        }


        getDetailColums('summaryColumns', setDetailTableColumns).catch((err) => console.log(err))
        getDetailColums('detailedColumns2', setDetailTableColumns2).catch((err) => console.log(err))
        getDetailedData(setDetailTableData).catch((err) => console.log(err))
        getDetailedData2(setDetailTableData2).catch((err) => console.log(err))
        getSummaryData('participantSummary', setParticipantSummaryData).catch((err) => console.log(err))
        getSummaryData('instructorTypeSummary', setInstructorTypeSummaryData).catch((err) => console.log(err))
        getSummaryData('instructorSummary', setInstructorSummaryData).catch((err) => console.log(err))
        getSummaryData('maintenanceSummary', setMaintenanceSummaryData).catch((err) => console.log(err))
        getSummaryData('bikeSummary', setBikeSummaryData).catch((err) => console.log(err))
        getSummaryData('fuelSummary', setFuelSummaryData).catch((err) => console.log(err))
    }, [period]);


    const participantTableFormat = [
        //{name: 'id', title: 'ID'},
        {name: 'first_name', title: 'Eesnimi'},
        {name: 'last_name', title: 'Perenimi'},
        {name: 'bike_sessions', title: 'Tsiklitrennides osalemine', type: number},
        {name: 'pe_sessions', title: 'ÜKE trennides osalemine', type: number},
        {name: 'bike_rentals', title: 'Klubi tsikli rentide arv', type: number},
        {name: 'maintenance', title: 'Hoolduste arv', type: number},
        {name: 'fuel_canisters', title: 'Kanistrite arv', type: number},
        //{name: 'total', title: 'Tasuda'},
    ]

    const sessionAllTableFormat = [
        {name: 'name', title: 'Treener'},
        {name: 'participant_count', title: 'Osalenute arv'},
        {name: 'participation_count', title: 'Osalemiste arv'}
    ]

    const sessionTableFormat = [
        {name: 'name', title: 'Treener'},
        {name: 'type_name', title: 'Trenni tüüp'},
        {name: 'participant_count', title: 'Osalenute arv'},
        {name: 'participation_count', title: 'Osalemiste arv'}
    ]

    const maintenanceTableFormat = [
        {name: 'name', title: 'Treener'},
        {name: 'maintenance_count', title: 'Hoolduste arv'},
    ]
    const bikeTableFormat = [
        {name: 'bike_name', title: 'Tsikkel'},
        {name: 'bike_count', title: 'Rentimiste arv'},
    ]
    const fuelTableFormat = [
        {name: 'fuel_name', title: 'Kütuse tüüp'},
        {name: 'fuel_canisters', title: 'Kanistrite arv'},
    ]

    return (
        <main className="data-main">
            <div className="table-selector four-buttons">
                <button
                    className={selectedTable === 'participants' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("participants")}
                >
                    Osalejad
                </button>
                <button
                    className={selectedTable === 'detailed' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("detailed")}
                >
                    Osalejad 2
                </button>
                <button
                    className={selectedTable === 'instructors' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("instructors")}
                >
                    Treenerid
                </button>
                <button
                    className={selectedTable === 'equipment' ? "selected-table-button" : ""}
                    onClick={() => setSelectedTable("equipment")}
                >
                    Varustus
                </button>
            </div>
            <div className='date-select'>
                <label>Periood:</label>
                <input type='date' value={period.start}
                       onChange={event => event.target.value === "" ? setPeriod({
                           ...period,
                           start: new Date(2020, 11, 1).toISOString().slice(0, 10)
                       }) : setPeriod({
                           ...period,
                           start: event.target.value
                       })}/>
                <label> - </label>
                <input type='date' value={period.end}
                       onChange={event => event.target.value === "" ? setPeriod({
                           ...period,
                           end: new Date().toISOString().slice(0, 10)
                       }) : setPeriod({
                           ...period,
                           end: event.target.value
                       })}/>
            </div>

            <div className={selectedTable === 'participants' ? "participant-table" : "hide-table"}>
                <h2>Osalejad</h2>
                <DataTable data={participantSummaryData} columns={participantTableFormat}/>
            </div>
            <div className={selectedTable === 'instructors' ? "instructor-tables" : "hide-table"}>
                <div className="instructor-tables-grid">
                    <div>
                        <h2>Treeningutel osalemine treenerite lõikes trennitüübi kaupa</h2>
                        <DataTable data={instructorTypeSummaryData} columns={sessionTableFormat}/>
                    </div>
                    <div>
                        <h2>Treeningutel osalemine treenerite lõikes kokku</h2>
                        <DataTable data={instructorSummaryData} columns={sessionAllTableFormat}/>
                    </div>
                </div>
            </div>
            <div className={selectedTable === 'equipment' ? "equipment-tables" : "hide-table"}>
                <h2>Lisakulutused</h2>
                <div className="equipment-tables-grid">
                    <DataTable data={maintenanceSummaryData} columns={maintenanceTableFormat}/>
                    <DataTable data={bikeSummaryData} columns={bikeTableFormat}/>
                    <DataTable data={fuelSummaryData} columns={fuelTableFormat}/>
                </div>
            </div>
            <div className={selectedTable === 'detailed' ? "detailed-table" : "hide-table"}>
                <div>
                    <div>
                        <h2>Tsiklite rentimiste arv tsiklite kaupa ja tsiklite hoolduste arv treenerite kaupa</h2>
                        <DataTable data={detailTableData} columns={detailTableColumns}/>
                    </div>
                    <div>
                        <h2>Trennides osalemised treenerite kaupa</h2>
                        <DataTable data={detailTableData2} columns={detailTableColumns2}/>
                    </div>
                </div>
            </div>
        </main>
    )
}

// https://devexpress.github.io/devextreme-reactive/react/grid/
function DataTable({data, columns}) {
    const [currencyColumns] = useState(['total']);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageSizes] = useState([5, 10, 50, 0]);

    // Stuff for export:
    const exporterRef = useRef(GridExporter);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]); //

    return (
        <Paper>
            <Grid
                rows={data}
                columns={columns}
                getRowId={getRowId}
            >
                <CurrencyTypeProvider
                    for={currencyColumns}
                />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <IntegratedPaging/>
                <SortingState
                    defaultSorting={[
                        {columnName: 'firstname', direction: 'asc'},
                        {columnName: 'lastname', direction: 'asc'},
                        {columnName: 'instructor', direction: 'asc'},
                    ]}
                />
                <IntegratedSorting/>
                <SearchState/>
                <IntegratedFiltering/>
                <Table
                    columnExtensions={[
                        {columnName: 'id', width: 70},
                        {columnName: 'first_name', width: 150},
                        {columnName: 'last_name', width: 150},
                        {columnName: 'bike_sessions', minWidth: 420, align: "center"},
                        {columnName: 'pe_sessions', minWidth: 320, align: "center"},
                        {columnName: 'bike_rentals', minWidth: 320, align: "center"},
                        {columnName: 'maintenance', maxWidth: 100, align: "center"},
                        {columnName: 'fuel_canisters', maxWidth: 100, align: "center"},
                        {columnName: 'sum', minWidth: 90, align: "center"},
                        {columnName: 'instructor', minWidth: 200}
                    ]}
                />
                <TableHeaderRow
                    showSortingControls/>
                <SummaryState
                    totalItems={[
                        {columnName: 'last_name', type: 'count'},
                        {columnName: 'total', type: 'sum'},
                        {columnName: 'bike_sessions', type: 'sum'},
                        {columnName: 'pe_sessions', type: 'sum'},
                        {columnName: 'bike_rentals', type: 'sum'},
                        {columnName: 'maintenance', type: 'sum'},
                        {columnName: 'bike_count', type: 'sum'},
                        {columnName: 'maintenance_count', type: 'sum'},
                        {columnName: 'fuel_canisters', type: 'sum'},
                        {columnName: 'participant_count', type: 'sum'},
                        {columnName: 'participation_count', type: 'sum'},
                        // too many places after comma, did not know how to fix
                        /*
                        {columnName: 'sessions', type: 'avg'},
                        {columnName: 'pe_sessions', type: 'avg'},
                        {columnName: 'bike_rentals', type: 'avg'},
                        {columnName: 'maintenance', type: 'avg'},
                        {columnName: 'fuel_canisters', type: 'avg'},
                        {columnName: 'participants', type: 'avg'},
                        {columnName: 'participation', type: 'avg'},*/
                    ]}
                />
                <IntegratedSummary/>
                <TableSummaryRow messages={{sum: "Kokku", count: "Kokku", avg: "Keskmine"}}/>
                <TableFixedColumns leftColumns={['firstname', 'lastname']}/>
                <PagingPanel
                    pageSizes={pageSizes}
                    messages={{showAll: "Kõik", rowsPerPage: "Ridu lehel"}}
                />
                <Toolbar/>
                <SearchPanel messages={{searchPlaceholder: "Otsi..."}}/>
                <ExportPanel startExport={startExport} messages={{showExportMenu: "Ekspordi"}}/>
            </Grid>
            <GridExporter
                ref={exporterRef}
                columns={columns}
                rows={data}
                onSave={onSave}
            />
        </Paper>
    )
}

const getRowId = row => row.id;

const CurrencyFormatter = ({value}) => (
    <b style={{color: 'darkblue'}}>
        {value.toLocaleString('et-EE', {style: 'currency', currency: 'EUR'})}
    </b>
);

const CurrencyTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
    />
);

const onSave = (workbook) => {
    workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: "application/octet-stream"}), "kokkuvote.csv");
    });
};

export default Summary;
